/*
RESET SETTINGS */

/* set border-box (so that css uses a
more reasonable box model, and so it’s
like every other platform) */
*, *::before, *::after {
    box-sizing: border-box;
    }

/* get rid of default margins which
vary depending on browser anyway, just
set it yourself later*/
* {
    margin: 0;
}

    /* media elements are replaced elements
which have a width based on the media,
so we prevent it from expanding beyond
its container, and take up a row */
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

/* make tables look like spreadsheets
and less like a spaced-out grid */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body{
    overflow-x: hidden;
}

a{
    color: red
}

 /* SCROLLBAR */

::-webkit-scrollbar-thumb:vertical {
    background: #303030;
    border-radius: 5px;
    height: 5px;
}

::-webkit-scrollbar {
    height: 5px;
    width: 3px;
}

